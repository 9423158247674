.blog_page_container {
  height: fit-content;
  width: 100%;
  background-color: #f8e8e6;
}

.blog_page_container h2 {
  padding-left: 8rem;
  padding-top: 10rem;
}

.blog_container {
  display: flex;
}

.blog_container img {
  border-radius: 1.5rem;
  width: 40%;
  height: 30rem;
  margin: 2rem 1.5rem 0 8rem;
}

.blog_content {
  padding: 2rem 8rem 0 1.5rem;
  text-align: justify;
}

.blog_content h3,
.blog_content p,
.blog_content button {
  margin-bottom: 2rem;
}

.read_more {
  background-color: #eb563b;
  border-radius: 2rem;
  border: none;
  padding: 0.4rem 0.9rem;
  color: white;
  transition: 0.3s;
}

.read_more:hover {
  background-color: #ff8300;
}

.category_pill {
  background-color: white;
  border-radius: 2rem;
  border: none;
  padding: 0.4rem 0.9rem;
  font-weight: 700;
  transition: 0.3s;
}

.category_pill:hover {
  background-color: #eed6bc;
}

.section_devision {
  color: rgb(153, 148, 148);
  margin: 0 8rem;
}

@media (max-width: 800px) {
  .blog_page_container {
    height: fit-content;
  }

  .blog_container {
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .blog_container img {
    border-radius: 1.5rem;
    width: 100%;
    height: auto;
    margin: 0;
    margin-bottom: 1rem;
  }

  .blog_page_container h2 {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    padding-top: 8rem;
  }

  .blog_content {
    padding: 0;
    text-align: justify;
  }

  .blog_card_container {
    height: fit-content;
  }
}
