
  h1 {
    font-size: clamp(20px, 4vw, 30px);
    line-height: 1.2;
    margin-bottom: 40px;
  }
  main {
    max-width: 520px;
    margin: 0 auto;
  }
  summary {
    font-size: 1.25rem;
    font-weight: 600;
    background-color: #f8ddd9;
    color: #333;
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    border-radius: 0.25rem;
    text-align: left;
    cursor: pointer;
    position: relative;
  }
  .faq__content{
    color: #777;
  }
  details[open] summary ~ * {
    animation: sweep .5s ease-in-out;
  }
  @keyframes sweep {
    0%    {opacity: 0; margin-top: -10px}
    100%  {opacity: 1; margin-top: 0px}
  }
  details > summary::after {
    position: absolute;
    content: "+";
    right: 20px;
  }
  details[open] > summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }
  details > summary::-webkit-details-marker {
    display: none;
  }