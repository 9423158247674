.blog_card_container {
  height: 40rem;
  width: 30rem;
  padding: 1rem;
}

.blog_card_container .blog_card_img_container img {
  object-fit: cover;
  border-radius: 1.5rem;
}

.blog_card_inner_container {
  display: flex;
  flex-direction: column;
}

.blog_card_inner_container h3,
.blog_card_inner_container p {
  margin: 1rem 0;
}

.pill-blog-img{
  width: 30rem;
  height: 18rem;
  object-fit: fill !important;
}

@media (max-width: 768px) {
  .blog_card_container {
    height: fit-content;
  }
}
