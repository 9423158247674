.shadow-effect {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ececec;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.02);
}

#shadow-effect p {
  font-family: inherit;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 17px 0;
  font-weight: 300;
}

.img-circle {
  border-radius: 50%;
  vertical-align: middle;
  max-width: 90px;
  min-height: 90px;
  transform-style: preserve-3d;
  margin: 0 auto 17px;
}

.testimonial-name {
  margin: -2rem auto 0;
  display: table;
  width: auto;
  /* background: linear-gradient(100deg, #845EC2, #BE93FD); */
  background: linear-gradient(100deg, #eb563b, #f37d68);
  /* background: linear-gradient(135deg, #ff3e00, #eaa400); */
  padding: 9px 35px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.12), 0px 5px 7px rgba(0, 0, 0, 0.5);
}

#customer-testimonoals .item {
  text-align: center;
  padding: 50px;
  margin-bottom: 0px;
  opacity: 0.2;
  transform: scale3d(0.8, 0.8, 1);
  transition: all 0.3s ease-in-out;
}

#customer-testimonoals .owl-item.active.center .item {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #56423d;
  transform: translate3d(0px, -50%, 0px) scale(0.8);
}

.owl-carousel .owl-dots {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}

.owl-carousel .owl-dots .owl-dot span {
  /* background: #eaa400; */
  background: #56423d;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 2px 5px;
  transform: translate3d(0px, -50%, 0px) scale(0.3);
  transform-origin: 50% 50% 0;
  transition: all 250ms ease-out 0s;
}

/* new design */

.snip1515 {
  font-family: "Open Sans", Arial, sans-serif;
  position: relative;
  margin: 10px;
  width: 400px;
  color: #000000;
  text-align: center;
  line-height: 1.4em;
  font-size: 14px;
  box-shadow: none !important;
  overflow: hidden;
}

.shadow-effect-card {
  height: 21rem;
  overflow: hidden;
}

.snip1515 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.snip1515 .profile-image {
  display: inline-block;
  z-index: 1;
  position: relative;
  /* border: 2px solid #fc853f; */
  border-radius: 100%;
}

.snip1515 .profile-image img {
  width: 8rem;
  height: 8rem;
  vertical-align: top;
  border-radius: 200%;
}

.snip1515 figcaption {
  width: 100%;
  background-color: #f2f2f2;
  color: #555;
  padding: 70px 25px 25px;
  margin-top: -60px;
  display: inline-block;
}

.snip1515 h3,
.snip1515 h4,
.snip1515 p {
  margin: 0 0 5px;
}

.snip1515 h3 {
  font-weight: 600;
  font-size: 1.3em;
  font-family: "Playfair Display", Arial, sans-serif;
}

.snip1515 h4 {
  color: #8c8c8c;
  font-weight: 400;
  letter-spacing: 2px;
}

.snip1515 p {
  font-size: 0.9em;
  letter-spacing: 1px;
  opacity: 0.9;
}

@media (max-width: 790px) {
  #customer-testimonoals .item {
    text-align: center;
    padding: 0;
    margin-bottom: 0px;
  }

  .snip1515 {
    margin: 0;
    width: 100%;
  }
}
