.blog_detail_container {
  margin: 2rem 1rem;
}

.blog_detail_container h2 {
  padding-left: 8rem;
}

.center-pill {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.mobile_cata_pills {
  display: none;
}

@media (max-width: 799px) {
  .mobile_cata_pills {
    display: block;
  }

  .mobile_cata_pills select {
    width: 100%;
    margin-bottom: 0.9rem;
    padding: 0.3rem 0.5rem;
    font-weight: bold;
  }

  .center-pill {
    display: none;
  }
}
