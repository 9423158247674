.article_upper_container {
  height: 35rem;
  width: 100%;
  display: flex !important;
}

.article_upper_container .article_cover {
  position: absolute;
  right: 0;
  border-radius: 1.5rem;
  width: 50%;
  height: 93%;
  margin: 1rem 0;
  object-fit: stretch;
}

@media (max-width: 768px) {
  .article_upper_container {
    height: fit-content;
    width: fit-content;
  }

  .article_upper_container .article_cover {
    position: initial;
    bottom: 0;
    width: 100vw;
    margin: 0;
    height: 40%;
  }

  .tofront {
    position: initial;
  }

  .article_main_container {
    margin: 0;
    padding: 0;
  }

  .sub_article_main_container {
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;
  }
}
.article_upper_container .article_cover_service {
  position: absolute;
  right: 0;
  border-radius: 1.5rem;
  width: 100%;
  height: 93%;
  margin: 1rem 0;
  object-fit: cover;
}

/* .article_img_container img {
  height: 100%;
  border: 3px solid blue;
} */

.para-img {
  border-radius: 1.5rem;
  width: 50%;
  height: 25rem;
  margin: 0.5rem 0 2rem 0;
  object-fit: stretch;
}

.para-t-li {
  padding-left: 0;
}

.para-t-li li {
  margin: 1rem 0;
}

@media (max-width: 1024px) {
  .para-img {
    width: 100%;
  }
}

@media (min-width: 1024px) {
  .article-con-vk{
    padding: 0 !important;
    margin: 0% !important;
  }
}